@font-face {
  font-family: 'shv-esg-icons';
  src: url('./fonts/shv-esg-icons.eot?7ihwl6');
  src: url('./fonts/shv-esg-icons.eot?7ihwl6#iefix') format('embedded-opentype'),
    url('./fonts/shv-esg-icons.ttf?7ihwl6') format('truetype'), url('./fonts/shv-esg-icons.woff?7ihwl6') format('woff'),
    url('./fonts/shv-esg-icons.svg?7ihwl6#shv-esg-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shv-esg-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Kohle:before {
  content: '\e90b';
  color: #4c2a04;
}
.icon-graphy:before {
  content: '\e900';
  color: #b93918;
}
.icon-tobacco:before {
  content: '\e901';
  color: #e8830e;
}
.icon-weapons:before {
  content: '\e902';
  color: #762501;
}
.icon-environmental-pollution-and-corruption:before {
  content: '\e903';
  color: #607410;
}
.icon-spirits:before {
  content: '\e904';
  color: #3e6485;
}
.icon-armor:before {
  content: '\e905';
  color: #9c9c9c;
}
.icon-oil-sands:before {
  content: '\e906';
}
.icon-human-and-labor-rights:before {
  content: '\e907';
  color: #588cbc;
}
.icon-nuclear-energy:before {
  content: '\e908';
  color: #6e6e6e;
}
.icon-gambling:before {
  content: '\e909';
  color: #c16509;
}
.icon-fracking:before {
  content: '\e90a';
  color: #894e22;
}

.MuiListItemAvatar-root span {
  font-size: 34px;
  text-align: center;
}

.MuiListItemAvatar-root {
  text-align: center;
}
.MuiListItemText-root.title {
  width: 420px;
  flex: none;
}
.Tooltip_wrapper {
  display: inline-flex;
  align-items: center;
  line-height: 1.1;
}

.Tooltip_wrapper svg {
  margin-left: 3px;
  width: 21px;
}

._MenuItem {
  white-space: break-spaces !important;
}

.current_positions_list li > div {
  width: 25% !important;
}

.list_heading_style {
  font-weight: 600;
  background-color: #f2f2f2;
}

.list_heading_style span {
  font-weight: 500;
}

/* */
.controversial_business_areas {
}

.controversial_business_areas > li {
}

.controversial_business_areas > li > div:not(:first-child) {
  width: 20%;
}

.controversial_business_areas > li > div:not(:first-child):not(:nth-child(2)) {
  text-align: left;
}

/* .controversial_business_areas > li > div:not(:first-child) + div {
    border-left: 1px solid #c4c4c4;
}

.current_positions_list > li > div + div {
    border-left: 1px solid #c4c4c4;
} */
/*
.controversial_business_areas,.current_positions_list {
    border: 1px solid #c4c4c4;
} */

/* .controversial_business_areas > li:last-child , .current_positions_list > li:last-child {
    border-bottom: none;
} */
/* */

li.MuiListItem-root {
  align-items: normal !important;
}
li.MuiListItem-root .MuiListItemAvatar-root {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.scroll-mobile {
  margin: 0;
}

@media (max-width: 767px) {
  ul.MuiList-root.scroll-mobile > li {
    width: 760px;
  }

  ul.MuiList-root.scroll-mobile {
    overflow: auto;
  }

  .MuiListItemText-root.title {
    width: 350px;
  }

  ._Tabs .MuiTabs-flexContainer button,
  ._Tabs .MuiTabs-flexContainer a {
    padding: 11px 10px !important;
  }
}
