@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 100;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 100;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 200;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 200;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 300;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 400;
  src: url('../fonts/IBMPlexSans/IBMPlexSans.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 500;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 500;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 600;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 700;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/IBMPlexSans/IBMPlexSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 200;
  src: url('../fonts/Changa/Changa-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 300;
  src: url('../fonts/Changa/Changa-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 400;
  src: url('../fonts/Changa/Changa.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 500;
  src: url('../fonts/Changa/Changa-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 600;
  src: url('../fonts/Changa/Changa-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 700;
  src: url('../fonts/Changa/Changa-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Changa';
  font-weight: 800;
  src: url('../fonts/Changa/Changa-ExtraBold.ttf') format('truetype');
}
