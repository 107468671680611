._Tabs {
  background: #ccd5df;
  display: flex;
  align-items: center;
  border-bottom: 0 !important;
}

._Tabs .MuiTabs-flexContainer {
  justify-content: space-around;
}

._Tabs .MuiTabs-flexContainer button,
._Tabs .MuiTabs-flexContainer a {
  background: transparent;
  padding: 0 10px 2px;
  min-height: auto;
  border-bottom: 2px solid transparent;
}
._Tabs .MuiTabs-flexContainer button:hover,
._Tabs .MuiTabs-flexContainer a:hover {
  color: #002d5c;
}

._Tabs .MuiTabs-flexContainer button.Mui-selected,
._Tabs .MuiTabs-flexContainer a.Mui-selected {
  color: #002d5c;
  border-bottom-color: #002d5c;
}

.MenuItem_inner {
  display: inline-block;
  width: 100%;
  position: relative;
  padding-right: 40px;
}

.MenuItem_inner svg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.Tooltip {
  position: relative;
  z-index: 9999;
}

.Tooltip .Tooltip-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  font-size: 14px;
  background: #5d5d5d;
  padding: 8px;
  top: 100%;
  border-radius: 5px;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  color: #fff;
}

.Tooltip:hover .Tooltip-content {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .Tooltip .Tooltip-content {
    left: auto;
    right: -60px;
    transform: translateX(0);
    width: 200px;
  }
}

li.pdf_table_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center !important;
}

.pdf_table_row .title {
  width: calc(100% - 40px);
}

.pdf_table_row .title + div {
  width: 40px;
}

.pdf_table_row .title + div a {
  display: block;
  line-height: 0;
}
.MuiCollapse-vertical {
  padding: 0 !important;
}

.MuiList-root {
  margin: 0 !important;
}

@media (min-width: 565px) {
  .pie-chart-wrapper {
    max-width: 500px;
  }
}

.pie-chart-wrapper .legend-mouseover-inactive.apexcharts-datalabels {
  opacity: 1 !important;
}
